import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { css } from "@emotion/core"
import { breakpoint } from "../constants"
import CommonBackground from "../components/CommonBackground"

const page = css`
  font-size: 0.2rem;
  text-align: center;
  min-height: 100vh;
  position: relative;
  padding-top: 1rem;
  box-sizing: border-box;
  @media (max-width: ${breakpoint}px) {
    font-size: 0.6rem;
    padding-top: 3rem;
  }
`

const background = css`
  width: 100vw;
  height: 100%;
  overflow: hidden;
  position: absolute;
  left: 0;
  top: 0;
  z-index: -10;
`

const NotFoundPage = () => {
  return (
    <Layout>
      <SEO title="404" />
      <div css={page}>
        <h1>NOT FOUND</h1>
        <p>
          Get lost? Click <Link to="/">here</Link> to go to home page
        </p>
        <div css={background}>
          <CommonBackground />
        </div>
      </div>
    </Layout>
  )
}

export default NotFoundPage
